import "../../css/index/sectionOne.scss"
import React, { useState } from "react"
import BackgroundImage from 'gatsby-background-image'
import Header from '../Header'
import { Link } from "gatsby"

const SectionOne = ({ tabletInverted, logo, logoBlack, location }) => {
  const [logoType, setLogo] = useState(logo)
  const image = tabletInverted
  const imageData = [
    `linear-gradient(rgba(28, 27, 27, 0.70), rgba(28, 27, 27, 0.70))`,
    image
  ]

  if (typeof window !== `undefined`) {
    const header = document.getElementById("header")
    const logoText = document.getElementById("logoText")
    if (header !== null && logoText !== null) {
      header.style.background = "rgba(255, 255, 255, 0)";
      logoText.style.color = "white"
    }
    if (window.location.href.indexOf("contact") < 0) {
      window.onscroll = function() {scrollFunction()};
      if (typeof location !== undefined && location?.state?.home) {
        window.scrollTo(0, 1);
        location.state.home = false
      }
    }
  }


  function scrollFunction() {
    const header = document.getElementById("header")
    const logoText = document.getElementById("logoText")
    if (window.location.href.indexOf("contact") < 0) {
      if (window.innerWidth < 500) {
        if (document.body.scrollTop > 635 || document.documentElement.scrollTop > 635) {
          header.style.background = "white";
          logoText.style.color = "black"
          setLogo(logoBlack)
        } else {
          header.style.background = "rgba(255, 255, 255, 0)";
          logoText.style.color = "white"
          setLogo(logo)
        }
      } else {
        if (document.body.scrollTop > 710 || document.documentElement.scrollTop > 710) {
          header.style.background = "white";
          logoText.style.color = "black"
          setLogo(logoBlack)
        } else {
          header.style.background = "rgba(255, 255, 255, 0)";
          logoText.style.color = "white"
          setLogo(logo)
        }
      }
      
    } 
    
  }
  
  return (
    <div className="root">
      <BackgroundImage
        Tag="section"
        className="image"
        fluid={imageData}
        preserveStackingContext
      >
        <Header 
          logo={logoType}
        />
        <div className="imageInnerWrapper"> 
          <div className="headingWrapper">
            <p className="heading">
              The Ultimate Mobile Business System Application, designed with entrepreneurs and teams in mind.
            </p>
            <div className='getInTouchWrapper'>
              <Link to="/contact" style={{ textDecoration: 'none' }}>
                <button className='getInTouch'>
                  Get In Touch
                </button>
              </Link>
            </div>
          </div>
        </div>
      </BackgroundImage>
    </div>
  )
}

export default SectionOne
