import "../../css/index/sectionTwo.scss"
import React from "react"
import Animation from './Animation'
import confirmOrder from '../../animations/confirm-order.mp4'
import notification from '../../animations/notification.mp4'

const SectionTwo = () => (
  <div className='sectionTwo'>
    <p className="topHeading">
        OrderDrive's Solutions:
    </p>
    <div className="topRowTwo">
      <Animation
        src={confirmOrder}
      />
      <div className="textColumnTop">
        <h2 className="dataDrivenText">
          Data Driven
        </h2>
        <p className="dataParagraph">
          Enable customers to make <br className="desktopBR"/>
          informed decisions based on <br className="desktopBR"/>
          real time order status updates.
        </p>
      </div>
    </div>
    <div className="bottomRowTwo">
      <div className="textColumnBottom">
        <h2 className="streamlineText">
          Streamline<br className="mobileBR"/> Operations
        </h2>
        <p className="streamlineParagraph">
          Mobilize merchandise in orders from a <br className="desktopBR"/>
          convenient, portable interface.
        </p>
      </div>
      <Animation
        src={notification}
        animationStyle="animationAddon"
      />
    </div>
  </div>
)

export default SectionTwo
