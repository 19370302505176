import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import SEO from "../components/SEO"
import Layout from "../components/Layout"
import SectionOne from '../components/index/SectionOne'
import AboutSection from '../components/index/AboutSection'
import SectionTwo from '../components/index/SectionTwo'
import SectionThree from '../components/index/SectionThree'
import SectionFour from '../components/index/SectionFour'

const IndexPage = ({ location }) => {
  const data = useStaticQuery(graphql`
    query {
      OD: file(relativePath: { eq:"images/OD.png" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1491, maxHeight: 842) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      ODBlack: file(relativePath: { eq:"images/ODBlack.png" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1491, maxHeight: 842) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      tablet: file(relativePath: { eq:"images/tablet.png" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 3840, maxHeight: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      },
      tabletInverted: file(relativePath: { eq:"images/tablet-inverted.png" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 3840, maxHeight: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      },
      tabletLong: file(relativePath: { eq:"images/tablet-long.png" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 3840, maxHeight: 516) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  const img = (name) => data[name].childImageSharp.fluid
  return (
    <Layout>
      <SEO title="Home"/>
      <SectionOne
        location={location}
        tablet={img('tablet')}
        tabletInverted={img('tabletInverted')}
        logo={img('OD')}
        logoBlack={img('ODBlack')}
      />
      <AboutSection />
      <SectionTwo />
      <SectionThree 
        tabletLong={img('tabletLong')}
      />
      <SectionFour />
    </Layout>
  )
}

export default IndexPage
