import "../../css/index/aboutSection.scss"
import React from "react"

const AboutSection = () => {

  return (
    <div className="aboutSection">
      <p className="topParagraph">
        OrderDrive is aligning consumers with brands to create a streamlined shopping & selling experience.
      </p>
      <p className="midParagraph">
        Let's face it, traditional customer service and business communication is dated and inefficient. Sitting on hold over the phone or waiting 3 days for a response by email doesn't work in our modern, fast paced world. Consumers like you need answers to questions to make informed decisions with their hard-earned cash, RIGHT NOW. 
      </p>
      <p className="midParagraph">
        On the other side, brands and manufacturers lose control of their image by outsourcing their products and pricing to distributors, for minimal value in return. It's about time product makers start reclaiming power over their own intellectual property.
      </p>
      <p className="midParagraph">
        Gone are the days of waiting for sub-par customer service through email and calls on hold over the phone.
      </p>
      <p className="midParagraph">
        No more guessing games with product information, pricing, shipping and availability.
      </p>
      <p className="midParagraph">
        As a brand, have your image no longer at the mercy of distributors & resellers.
      </p>
    </div>
  )
}

export default AboutSection
