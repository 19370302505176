import "../../css/index/sectionFour.scss"
import React from "react"
import Animation from './Animation'
import restock from '../../animations/restock.mp4'
import chat from '../../animations/chat.mp4'

const SectionFour = () => (
  <div className="sectionFour">
    <div className="topRowFour">
      <div className="textColumnTop">
        <h2 className="restockText">
          Automate<br className="mobileBR"/> Routine Tasks
        </h2>
        <p className="restockParagraph">
          As a manufacturer, automatically re-stock <br className="desktopBR"/> and re-purchase items based on predefined limits. <br className="desktopBR"/>Save time & empower your team to act efficiently.
        </p>
      </div>
      <Animation
        src={restock}
        animationStyle="animationAddon"
      />
    </div>
    <div className="bottomRowFour">
      <Animation
        src={chat}
        animationStyle="animationAddon"
      />
      <div className="textColumnBottom">
        <h2 className="intuitiveText">
          Intuitive<br className="mobileBR"/> Communications
        </h2>
        <p className="intuitiveParagraph">
          Calls are time consuming. Emails are a burden. <br className="desktopBR"/>Communicate directly with manufacturers about <br className="desktopBR"/>specific products. Get answers fast.
        </p>
      </div>
    </div>
  </div>
)

export default SectionFour
