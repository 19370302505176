import React from "react"
import "../../css/index/sectionTwo.scss"

const Animation = ({src, animationStyle}) => (
  <video className={`animation ${animationStyle}`} autoPlay={true} loop={true} muted={true} playsInline>
    <source src={src} type={src.includes('.webm') ? "video/webm" : "video/mp4"} />
  </video>
)

export default Animation
