import "../../css/index/sectionThree.scss"
import React from "react"
import BackgroundImage from 'gatsby-background-image'

const SectionThree = ({ tabletLong }) => {
  const imageData = [
    `linear-gradient(rgba(28, 27, 27, 0.92), rgba(28, 27, 27, 0.92))`,
    tabletLong,
  ]
  return (
    <BackgroundImage
      Tag="section"
      className="imgRoot"
      fluid={imageData}
      preserveStackingContext
    >
    <div className="textWrapper">
      <p className="text">
        Our Drive is to Revolutionize Business Operations & Communications.
      </p>
    </div>
    </BackgroundImage>
  )
}

export default SectionThree
